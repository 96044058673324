@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;

        --radius: 0.5rem;

        color-scheme: dark;
        scrollbar-width: thin;

        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;

        --navbar-height: 4rem;

        --chart-color-line: 40 20% 90%;
        --chart-color-negative: 355 64% 33%;
        --chart-color-positive: 180 51% 24%;
        --chart-color-base: 207 66% 26%;

    }

    .dark {
        --background: 242 2% 8.5%;
        --foreground: 0 0% 98%;

        --card: 219 5% 12%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 72.8% 60.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;
        --input: 0 0% 22.9%;
        --ring: 0 0% 83.1%;

        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Light.ttf) format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Regular.ttf) format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Medium.ttf) format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-SemiBold.ttf) format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Bold.ttf) format("opentype");
    font-weight: 700;
    font-style: normal;
}

@layer base {
    html, body {
        font-family: 'Poppins', serif;
        margin: 0;
        padding: 0;
    }
}

@layer base {
    * {
        font-family: 'Poppins', serif;
        @apply border-border;
    }

    body {
        @apply dark bg-background h-full text-foreground;
    }
}

::-webkit-scrollbar-track {
    background: hsl(var(--background));
}

::-webkit-scrollbar-thumb {
    background: hsl(var(--muted-foreground));

}

::-webkit-scrollbar-thumb:hover {
    background: hsl(var(--card-foreground));
}

.card-shine-effect {
    --shine-deg: 45deg;
    position: relative;
    overflow: hidden;

    background-repeat: no-repeat;
    background-position: -100% 0, 0 0;

    background-image: linear-gradient(
            var(--shine-deg),
            transparent 20%,
            transparent 40%,
            rgb(68, 68, 68, 0.2) 50%,
            rgb(68, 68, 68, 0.2) 55%,
            transparent 70%,
            transparent 100%
    );

    background-size: 250% 250%, 100% 100%;
    transition: background-position 0s ease;
}

.card-shine-effect:hover {
    background-position: 200% 0, 0 0;
    transition-duration: 1.5s;
}

.card-shine-effect-continuous {
    --shine-deg: 45deg;
    position: relative;
    overflow: hidden;

    background-repeat: no-repeat;
    background-position: -100% 0, 0 0;

    background-image: linear-gradient(
            var(--shine-deg),
            transparent 20%,
            transparent 40%,
            rgb(68, 68, 68, 0.2) 50%,
            rgb(68, 68, 68, 0.2) 55%,
            transparent 70%,
            transparent 100%
    );

    background-size: 250% 250%, 100% 100%;
    animation: shine 1.5s infinite linear;
}

@keyframes shine {
    0% {
        background-position: -100% 0, 0 0;
    }
    100% {
        background-position: 200% 0, 0 0;
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}


/* Default thin scrollbar */
.hover-scrollbar {
    scrollbar-gutter: stable;
    overflow: auto; /* Enables scrolling */
    scrollbar-color: transparent transparent; /* Hides scrollbar initially */
}

/* Webkit Browsers (Chrome, Edge, Safari) */
.hover-scrollbar::-webkit-scrollbar {
    width: 2px; /* Initially hidden */
    background: transparent;
}

.hover-scrollbar::-webkit-scrollbar-thumb {
    background: transparent; /* Initially hidden */
    border-radius: 10px;
}

/* Show scrollbar on hover */
.hover-scrollbar:hover {
    scrollbar-color: hsl(var(--muted-foreground)) transparent;
}
